import { BASE_URL_PRODUCCION } from "../../utils/Constants"

export const obtenerDireccionApi = async (idPersona) => {
    try {
        const params = {
            headers:{
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            }
        }      
        const url = `${BASE_URL_PRODUCCION}/personadireccion?idPersona=${idPersona}`
        const res = await fetch(url,params)
        const resJSON = await res.json()
        return resJSON
    } catch (error) {
        console.log("Error :", error)
    }
}

export const guardarDireccionApi = async (data, idPersona) => {
    try {
        const url = `${BASE_URL_PRODUCCION}/personadireccion`
        const { calle, numeroCasa, numeroDepartamento, region, comuna, tipoDireccion } = data;
        const params = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: JSON.stringify({
                direccion: calle,
                id_persona: idPersona,
                id_comuna: comuna,
                id_region: region,
                numero_departamento: numeroDepartamento,
                numero: numeroCasa,
                id_direccion_tipo: tipoDireccion
            })
        }
        const res = await fetch(url, params)
        const resJSON = await res.json()

        return resJSON

    } catch (error) {
        console.log("Error :", error)
    }
}

export const actualizarDireccionApi = async (data, idPersona) => {
    try {
        const url = `${BASE_URL_PRODUCCION}/personadireccion`
        const { calle, numeroCasa, numeroDepartamento, region, comuna, tipoDireccion } = data;
        const params = {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            },
            body: {
                direccion: calle,
                id_persona: idPersona,
                id_comuna: comuna,
                id_region: region,
                numero_departamento: numeroDepartamento,
                numero: numeroCasa,
                id_direccion_tipo: tipoDireccion
            },
        }
        const res = await fetch(url, params)
        const resJSON = await res.json()

        return resJSON
    } catch (error) {
        console.log("Error: ", error)
    }
}